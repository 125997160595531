import { ApiResponseStatus, IBaseResponse } from "domains/interfaces/apiResponse"
import type {
  CalendarEventSlot,
  IAppointment,
  IAppointmentAvailableCounselorResponse,
  IAppointmentCasenoteGetByIdResponse,
  IAppointmentListResponse,
  IAppointmentPage,
  IAppointmentTimeSlotWithCounselor,
  IAppointmentTimeSlotWithCounselorResponse,
  IAppointmentTimeSlotsResponse,
  IAssignCounselorRequest,
  IAssignCounselorResponse,
  IBookOption,
  IBookOptionPayload,
  IBookOptionResponse,
  IHoliday,
  INewAppointmentFaculty,
  INewAppointmentLocation,
  INewAppointmentTimeSlot,
  INewCalendarItem,
  IPaginationMetadata,
  ITimeSlot,
  IUpdateNewAppointmentTimeSlot,
  IUserAppointmentHistoryResponse,
  IUserAppointmentHistoryView,
  IWeeklyOption,
} from "domains/interfaces/backoffice/appointment"
import type { AppointmentDateType } from "domains/types/appointment"
import { inject, injectable } from "inversify"
import type { Moment } from "moment"
import moment from "moment"
import type {
  IAppointmentMakeAnswer,
  IAppointmentTimeSlot,
} from "../domains/interfaces/appointment"
import type { IApiService } from "./apiService"
import type { IDateService } from "./dateService"
import type { IFileService } from "./fileService"
import { TYPES } from "./types"

import type { IMedicine, IMedicineListDTO } from "domains/interfaces/backoffice/medication"
import type {
  Appointment,
  IBackofficeAppoinmentResponse,
} from "domains/interfaces/backoffice/telemed/appointment"
import type { FollowUpPayload } from "domains/interfaces/backoffice/telemed/schedule"
import type { IForm, IFormApiResponse, IFormResponseAnswer } from "domains/interfaces/form"
import type { CounselorType } from "domains/interfaces/profile"
import type { ICounselorWithUser } from "domains/interfaces/user"
import { FormItemType } from "domains/types/form"

export interface IBackofficeAppointmentService {
  list(filter?: any): Promise<IAppointmentPage>
  getById(id: number): Promise<IAppointment>
  convertSlotToDates(slot: IAppointmentTimeSlot): { start: Date; end: Date; id: number }[]
  getAllTimeSlots(): Promise<IAppointmentTimeSlot[]>
  getCounselorTimeSlots(from: any, to: any): Promise<ITimeSlot[]>
  setCounselorTimeSlot(slots: any[]): Promise<void>
  removeCounselorTimeSlot(slots: any[]): Promise<any>
  getAllCounselorTimeSlots(from: string, to: string): Promise<IAppointmentTimeSlotWithCounselor[]>
  getCounselorAllTimeSlots(): Promise<ITimeSlot[]>
  mergeAllTimeSlots(
    appointment: IAppointment,
    counselorTimeSlots: IAppointmentTimeSlotWithCounselor[],
    allTimeSlots: IAppointmentTimeSlot[]
  ): CalendarEventSlot[]
  assignCounselorToAppointment(data: IAssignCounselorRequest): Promise<IAppointment>
  getAvailableCounselor(date: string, slotId: number): Promise<ICounselorWithUser[]>
  getCounselorList(type?: CounselorType): Promise<any>
  getAdminList(): Promise<any>
  deleteAppointment(appointment: IAppointment, deleteAnswer: IFormResponseAnswer[]): Promise<void>
  createPsychiatristAppointment(
    appointmentId: number,
    shouldCloseCurrent: boolean
  ): Promise<IAppointment>
  adminUpdateFollowupStatus(appointmentId: number, form: any): Promise<IAppointment>

  getCounselorSlot(counselorId: number, date: string): Promise<ITimeSlot[]>

  // Get form
  getAppointmentDeleteForm(): Promise<IForm>
  getAppointmentCompleteForm(): Promise<IForm>
  getAppointmentCompleteNoshowForm(): Promise<IForm>
  getAppointmentFollowupForm(): Promise<IForm>
  getAppointmentCancelForm(): Promise<IForm>
  getManageTimeForm(): Promise<IForm>

  getCounselorCompleteAppointmentForm(): Promise<IForm>

  getAppointmentHistory(filter: any): Promise<any>
  saveAppointment(
    data: any,
    appointmentId: number,
    formAnswers: IFormResponseAnswer[]
  ): Promise<any>
  confirmAppointment(appointmentId: number): Promise<any>
  completeAppointment(formAnswers: IFormResponseAnswer[], appointmentId: number): Promise<void>
  completeAppointmentFollowup(followPayload: FollowUpPayload, appointmentId: number): Promise<void>
  completeNoshowAppointment(
    formAnswers: IFormResponseAnswer[],
    appointmentId: number
  ): Promise<void>

  // My Appointment
  getMyAppointment(): Promise<Appointment[]>
  getMyAppointmentById(appointmentId: number): Promise<any>
  getMyAppointmentHistory(filter: any): Promise<any>

  // Casenote
  getCasenoteById(id: number): Promise<any>
  addCasenote(appointmentId: number, casenote: any): Promise<any>
  updateCasenote(appointmentId: number, casenoteId: number, casenote: any): Promise<any>
  closeAppointment(formAnswers: IFormResponseAnswer[], appointmentId: number): Promise<void>
  closeAppointmentWithFollowup(
    formAnswers: IFormResponseAnswer[],
    appointmentId: number
  ): Promise<void>
  makeAppointment(answer: IAppointmentMakeAnswer, userId: number): Promise<any>
  removeAppointmentSlot(appointmentId: number, formAnswers: IFormResponseAnswer[]): Promise<any>
  createUser(info: any): Promise<any>
  getAppointmentReportExcelLink(): Promise<string>
  getAllReportToken(): Promise<any>
  getReportQuery(from: any, to: any, page: number): Promise<any>
  getLastPrescription(appointmentId: number): Promise<IAppointment | null>

  // Get AllCounselor
  getUserAppointmentHistoryById(
    userId: string,
    appointmentId?: number,
    query?: Record<string, string | number>
  ): Promise<IUserAppointmentHistoryView>

  changeBookOption(appointmentId: number, payload: IBookOptionPayload): Promise<IBookOption>

  // Medication Details
  getMedicineList(): Promise<Array<IMedicine>>

  // Revert Status
  revertAppointmentStatus(id: number, reason: string): Promise<any>

  createTimeSlot(data: INewAppointmentTimeSlot): Promise<IAppointment>

  getTimeSlotByDate(counselor_id: number, date: Date): Promise<Object>
  getTimeSlotById(counselor_id: number): Promise<Object>

  // TODO: Implement this
  createNewTimeSlot(data: INewAppointmentTimeSlot): Promise<Object>
  getLocationList(): Promise<INewAppointmentLocation[]>
  getWeeklyList(): Promise<IWeeklyOption[]>
  getFacultyList(): Promise<INewAppointmentFaculty[]>
  updateNewTimeSlot(data: IUpdateNewAppointmentTimeSlot): Promise<void>
  deleteNewTimeSlot(slot_id: number, date: string, all_series: boolean): Promise<void>

  updateTimeSlot(
    id: number,
    counselor_id: number,
    counselor_type: String,
    date: String,
    type: String,
    start_time: String,
    end_time: String,
    appointment_time_slot_place_id: number,
    capacity: number,
    routine_start_date: String,
    routine_end_date: String,
    frequency: String,
    is_routine: boolean,
    faculty_id: []
  ): Promise<IAppointment>

  deleteTimeSlotById(id: number): Promise<void>

  getMyAppointmentByDateRange(
    from: string,
    to: string,
    mode: "admin" | "counselor" | "psychiatrist",
    status: string,
    caseOwner: string,
    service: string
  ): Promise<{ appointments: IAppointment[]; meta: IPaginationMetadata }>
  getMyAppointmentByDate(
    date: string,
    mode: "admin" | "counselor" | "psychiatrist",
    status: string,
    caseOwner: string,
    service: string
  ): Promise<{ appointments: IAppointment[]; meta: IPaginationMetadata }>

  getCalendarByDateRange({
    from,
    to,
    counselor_id,
    counselor_type,
    service_type,
    filter,
  }: {
    from: string
    to: string
    counselor_id?: number
    counselor_type?: string
    service_type?: string
    filter: "all" | "available" | "occupied"
  }): Promise<INewCalendarItem[]>
  getHolidays(): Promise<IHoliday[]>
}

@injectable()
export class BackofficeAppointmentService implements IBackofficeAppointmentService {
  private apiService: IApiService
  private dateService: IDateService
  private fileService: IFileService

  constructor(
    @inject(TYPES.IApiService) apiService: IApiService,
    @inject(TYPES.IDateService) dateService: IDateService,
    @inject(TYPES.IFileService) fileService: IFileService
  ) {
    this.apiService = apiService
    this.dateService = dateService
    this.fileService = fileService
  }
  public async getAppointmentCancelForm(): Promise<IForm> {
    const response = await this.apiService.get<any>("backoffice/appointment/removeTimeForm")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.form
  }

  public async getManageTimeForm(): Promise<IForm> {
    const response = await this.apiService.get<any>("backoffice/appointment/manageTimeForm")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.form
  }

  public async changeBookOption(
    appointmentId: number,
    payload: IBookOptionPayload
  ): Promise<IBookOption> {
    const url = `backoffice/appointment/${appointmentId}/bookoption`
    const response = await this.apiService.post<IBookOptionResponse>(url, payload)

    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async list(filter: any = { page: 1, size: 10 }): Promise<IAppointmentPage> {
    const response = await this.apiService.get<IAppointmentListResponse>(
      "backoffice/appointment",
      filter,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async getById(id: number): Promise<IAppointment> {
    const response = await this.apiService.get<any>(`backoffice/appointment/${id}`, {}, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data?.appointment) {
      throw new Error(response.data.message)
    }

    const { appointment } = response.data.data
    const { counselor = {} } = appointment

    return {
      ...appointment,
      counselor: {
        ...counselor,
        avatar_image: counselor && this.fileService.getFileUrl(counselor.avatar_image),
      },
    }
  }

  public async getAllTimeSlots(): Promise<IAppointmentTimeSlot[]> {
    const response = await this.apiService.get<IAppointmentTimeSlotsResponse>(
      "backoffice/appointment/slot"
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data?.slots) {
      throw new Error(response.data.message)
    }
    return response.data.data?.slots
  }

  public convertSlotToDates(slot: IAppointmentTimeSlot): { start: Date; end: Date; id: number }[] {
    const dayOfWeek = slot.day
    const days = this.dateService.getDaysWithInMonth(dayOfWeek, 2)
    const eventDates: { start: Date; end: Date; id: number }[] = []
    for (const day of days) {
      const [startHour, startMinute] = slot.start_time.split(":").map(x => Number(x))
      const [endHour, endMinute] = slot.end_time.split(":").map(x => Number(x))
      const [startTime, endTime] = this.dateService.getStartEndDate(
        day,
        startHour,
        startMinute,
        endHour,
        endMinute
      )
      eventDates.push({
        start: startTime.toDate(),
        end: endTime.toDate(),
        id: slot.id,
      })
    }
    return eventDates
  }

  public async getCounselorTimeSlots(from: Moment, to: Moment): Promise<ITimeSlot[]> {
    const response = await this.apiService.get<any>(
      "backoffice/counselor/appointment/timeslot",
      { from: from.format("YYYY-MM-DD"), to: to.format("YYYY-MM-DD") },
      true
    )

    if (response.status !== ApiResponseStatus.Ok || !response.data) {
      throw new Error(response.data.message)
    }
    const data: ITimeSlot[] = response.data
    /*.map(
      (val: ITimeSlot & { slots: string; appointments: string }) => ({
        ...val,
        // pivot: JSON.parse(val.slots),
        // appointments: JSON.parse(val.appointments),
      })
    )*/

    return data
  }

  public async getCounselorAllTimeSlots(): Promise<ITimeSlot[]> {
    const response = await this.apiService.get<ITimeSlot[] | IBaseResponse<{}>>(
      "backoffice/counselor/appointment/timeslot/calendar",
      {
        from: moment().add(-1, "year").format("YYYY-MM-DD"),
      }
    )

    if (response.status !== ApiResponseStatus.Ok || !(response.data as IBaseResponse<{}>)) {
      throw new Error((response.data as IBaseResponse<{}>).message)
    }

    const timeslots = response.data as ITimeSlot[]

    return timeslots.map(timeslot => {
      const slots = JSON.parse(timeslot.slots as string)

      return {
        ...timeslot,
        slots: slots,
      }
    })
  }

  public async setCounselorTimeSlot(slots: any[]): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<{}>>(
      "backoffice/counselor/appointment/slot",
      { slots },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.errors as string)
    }
  }

  public async removeCounselorTimeSlot(slots: any[]): Promise<any> {
    const response = await this.apiService.delete<IBaseResponse<{}>>(
      "backoffice/counselor/appointment/slot",
      { slots },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.errors as string)
    }

    return response.data.data
  }

  public async getAllCounselorTimeSlots(
    from: string,
    to: string
  ): Promise<IAppointmentTimeSlotWithCounselor[]> {
    const response = await this.apiService.get<IAppointmentTimeSlotWithCounselorResponse>(
      "backoffice/appointment/slot/counselor/all",
      { from: from, to: to },
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.errors as string)
    }
    return response.data.data.slots
  }

  public mergeAllTimeSlots(
    appointment: IAppointment,
    counselorTimeSlots: IAppointmentTimeSlotWithCounselor[],
    allTimeSlots: IAppointmentTimeSlot[]
  ): CalendarEventSlot[] {
    const customerTimeSlots = appointment.slots
    const days: AppointmentDateType[] = []
    for (const slot of allTimeSlots) {
      if (days.indexOf(slot.day) === -1) {
        days.push(slot.day)
      }
    }
    const allDates: Moment[] = []
    for (const day of days) {
      allDates.push(...this.dateService.getDaysWithInMonth(day, 2))
    }
    const customerDates: Moment[] = []
    const customerDays: AppointmentDateType[] = []
    for (const slot of customerTimeSlots) {
      if (customerDays.indexOf(slot.day) === -1) {
        customerDays.push(slot.day)
      }
    }
    for (const day of customerDays) {
      customerDates.push(...this.dateService.getDaysWithInMonth(day, 2))
    }
    const eventSlots: CalendarEventSlot[] = []
    for (const date of allDates) {
      const day = date.format("dddd").toLowerCase()
      const customerSlots = customerTimeSlots.filter(x => x.day === day)
      const daySlots = allTimeSlots.filter(x => x.day === day)
      const dateString = date.format("YYYY-MM-DD")
      for (const slot of daySlots) {
        let isCustomer = false
        if (customerSlots.find(x => x.id === slot.id)) {
          isCustomer = true
        }
        let isCounselor = false
        if (
          counselorTimeSlots.find(x => {
            return dateString === x.date && x.id === slot.id
          })
        ) {
          isCounselor = true
        }
        const [startHour, startMinute] = slot.start_time.split(":").map(x => Number(x))
        const [endHour, endMinute] = slot.end_time.split(":").map(x => Number(x))
        const startDate = date
          .clone()
          .startOf("day")
          .add(startHour, "hours")
          .add(startMinute, "minutes")
        const endDate = date.clone().startOf("day").add(endHour, "hour").add(endMinute, "minutes")
        // Check if slot is a selected slot
        const [appointmentStartHour, appointmentStartMinute] =
          appointment.start_time?.split(":").map(x => Number(x)) ?? []
        const [appointmentEndHour, appointmentEndMinute] =
          appointment.end_time?.split(":").map(x => Number(x)) ?? []
        const isAppointed =
          appointment.date === dateString &&
          startHour === appointmentStartHour &&
          startMinute === appointmentStartMinute &&
          endHour === appointmentEndHour &&
          endMinute === appointmentEndMinute
        eventSlots.push({
          slot,
          isCounselor,
          isCustomer,
          start: startDate,
          end: endDate,
          date: date.clone().startOf("day"),
          isAppointed,
        })
      }
    }
    return eventSlots
  }

  public async assignCounselorToAppointment(data: IAssignCounselorRequest): Promise<IAppointment> {
    const response = await this.apiService.post<IAssignCounselorResponse>(
      "backoffice/appointment/assign",
      data,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointment
  }

  public async getAvailableCounselor(date: string, slotId: number): Promise<ICounselorWithUser[]> {
    const response = await this.apiService.get<IAppointmentAvailableCounselorResponse>(
      "backoffice/appointment/counselor/available",
      { date, slotId },
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.counselors
  }

  public async getCounselorList(type?: CounselorType): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/counselor?type=${type || ""}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data) {
      throw new Error(response.data.message)
    }
    return response.data
    // return response.data.data.appointments
  }

  public async getAdminList(): Promise<any> {
    const response = await this.apiService.get<any>(`backoffice/hotline/listAdmins`, {}, true)
    if (response.status !== ApiResponseStatus.Ok || !response.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async deleteAppointment(
    appointment: IAppointment,
    deleteAnswer: IFormResponseAnswer[]
  ): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<void>>(
      "backoffice/appointment/delete",
      {
        id: appointment.id,
        deleteAnswer,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async createPsychiatristAppointment(
    appointmentId: number,
    shouldCloseCurrent: boolean
  ): Promise<IAppointment> {
    const response = await this.apiService.post<any>(
      "backoffice/appointment/enablePsychiatrist",
      {
        appointmentId,
        shouldCloseCurrent,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.appointment
  }

  public async getAppointmentDeleteForm(): Promise<IForm> {
    const response = await this.apiService.get<IFormApiResponse>(
      "backoffice/appointment/delete/form"
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async getAppointmentCompleteForm(): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/appointment/counselorCompleteform")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async getAppointmentCompleteNoshowForm(): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/appointment/noshowform")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async getAppointmentFollowupForm(): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/appointment/followupform")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async getCounselorCompleteAppointmentForm(): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/appointment/counselorCompleteform")
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.form
  }

  public async saveAppointment(
    data: any,
    appointmentId: number,
    formAnswers: IFormResponseAnswer[]
  ): Promise<any> {
    const response = await this.apiService.post<IBaseResponse<any>>(
      `backoffice/appointment/${appointmentId}`,
      { ...data, formAnswers },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointment
  }

  public async confirmAppointment(appointmentId: number): Promise<any> {
    const response = await this.apiService.post<any>(
      `backoffice/appointment/${appointmentId}/confirm`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.status + "")
    }
    return response.data.data.appointment
  }

  public async completeAppointment(
    formAnswers: IFormResponseAnswer[],
    appointmentId: number
  ): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<void>>(
      `backoffice/appointment/${appointmentId}/complete`,
      { formAnswers },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async completeNoshowAppointment(
    formAnswers: IFormResponseAnswer[],
    appointmentId: number
  ): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<void>>(
      `backoffice/appointment/${appointmentId}/noshow`,
      { formAnswers },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async getMyAppointment(): Promise<Appointment[]> {
    const url = "backoffice/counselor/appointment"
    const param = {
      page: 1,
      size: 10000,
    }

    const response = await this.apiService.get<IBackofficeAppoinmentResponse>(url, param)

    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }

    return response.data.data.appointments
  }

  public async getMyAppointmentById(appointmentId: number): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/counselor/appointment/${appointmentId}`
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointments
  }

  public async getAppointmentHistory(filter: any): Promise<any> {
    const response = await this.apiService.get<any>("backoffice/appointment/history", filter)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async getMyAppointmentHistory(filter: any): Promise<any> {
    const response = await this.apiService.get<any>(
      "backoffice/counselor/appointment/history",
      filter
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { appointments, meta } = response.data.data
    const formatAppointment = appointments.map((appointment: any) => {
      const customer = appointment.customer || {}
      const user = customer.user || {}
      const customerProfile = appointment.customerProfile || {}

      let appointmentDate = ""

      if (appointment.date) {
        if (appointment.start_time && appointment.end_time) {
          appointmentDate = `${moment(appointment.date).format("yyyy-MM-DD")} ${moment(
            appointment.start_time,
            "hh:mm"
          ).format("HH:mm")} - ${moment(appointment.end_time, "hh:mm").format("HH:mm")}`
        } else if (appointment.start_time && !appointment.end_time) {
          appointmentDate = `${moment(appointment.date).format("yyyy-MM-DD")} ${moment(
            appointment.start_time,
            "hh:mm"
          ).format("HH:mm")}`
        } else {
          appointmentDate = `${moment(appointment.date).format("yyyy-MM-DD")}`
        }
      }
      return {
        id: appointment.id,
        userId: customer?.user_id,
        appointment_date: appointmentDate,
        firstname: user?.firstname,
        lastname: user?.lastname,
        riskScore: appointment.riskScore,
        dScore: appointment.dScore,
        aScore: appointment.aScore,
        sScore: appointment.sScore,
        gender: user?.sex === "male" ? "ชาย" : user.sex === "female" ? "หญิง" : "ไม่ระบุ",
        studentId: user?.username,
        cn: customer?.cn,
        idnumber: customer?.idnumber,
        year: customerProfile?.year,
        faculty: customerProfile?.faculty,
        casenote: appointment.casenotes ?? null,
        counselor_id: appointment.counselor_id,
        problem: appointment.problem,
        serviceType: appointment.service_type,
        position_type: appointment.counselor?.position_type,
        last_status: appointment.last_status,
        consult_with_type: appointment.consult_with_type,
      }
    })
    return { appointments: formatAppointment, total: meta.total, totalPage: meta.totalPage }
  }

  // TODO: ส่ง is_draft ด้วย true, false
  public async addCasenote(appointmentId: number, casenote: any): Promise<any> {
    const response = await this.apiService.post<IBaseResponse<any>>(
      `backoffice/appointment/${appointmentId}/casenote`,
      casenote,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointmentCasenote
  }

  public async updateCasenote(
    appointmentId: number,
    casenoteId: number,
    casenote: any
  ): Promise<any> {
    const response = await this.apiService.post<IBaseResponse<any>>(
      `backoffice/appointment/${appointmentId}/casenote/${casenoteId}`,
      casenote,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointmentCasenote
  }

  public async getCasenoteById(id: number): Promise<any> {
    const response = await this.apiService.get<IAppointmentCasenoteGetByIdResponse>(
      `backoffice/appointment/casenote/${id}`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data?.casenote) {
      throw new Error(response.data.message)
    }
    return response.data.data.casenote
  }

  public async closeAppointment(
    formAnswers: IFormResponseAnswer[],
    appointmentId: number
  ): Promise<void> {
    const response = await this.apiService.post<IBaseResponse<void>>(
      `backoffice/counselor/appointment/${appointmentId}/complete`,
      { formAnswers },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async closeAppointmentWithFollowup(formAnswers: [], appointmentId: number): Promise<void> {
    const response = await this.apiService.post<any>(
      `backoffice/counselor/appointment/${appointmentId}/followup`,
      { formAnswers },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointment
  }

  public async completeAppointmentFollowup(
    followPayload: FollowUpPayload,
    appointmentId: number
  ): Promise<void> {
    const response = await this.apiService.post<any>(
      `backoffice/counselor/appointment/${appointmentId}/followup`,
      followPayload,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.appointment
  }

  public async makeAppointment(answer: IAppointmentMakeAnswer, userId: number): Promise<void> {
    const response = await this.apiService.post<any>(
      `backoffice/appointment/create/${userId}`,
      answer,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    const { appointment } = response.data.data
    return appointment
  }

  public async createUser(info: any): Promise<any> {
    console.log(info)
    const response = await this.apiService.post<any>(`backoffice/appointment/user`, info, true)
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return response.data.data.user
  }

  public async removeAppointmentSlot(
    appointmentId: number,
    formAnswers: IFormResponseAnswer[]
  ): Promise<any> {
    const response = await this.apiService.delete<any>(
      `appointment/${appointmentId}`,
      {
        formAnswers,
      },
      true
    )
    if (response.status === ApiResponseStatus.NotFound) {
      // console.log(response);
      return response.data.message
    }
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
    return null
  }

  public async getAppointmentReportExcelLink(): Promise<string> {
    const token = await this.getAllReportToken()
    const url = this.apiService.getApiUrl("backoffice/appointment/report/all")
    return `${url}?one-time-token=${token.token}`
  }

  public async getAllReportToken(): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/appointment/report.xlsx/token`,
      {},
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data.oneTimeToken
  }

  public async getReportQuery(from: any, to: any, page: number): Promise<any> {
    const response = await this.apiService.get<any>(
      `backoffice/appointment/report`,
      {
        from,
        to,
        page,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }
    return response.data.data
  }

  public async getLastPrescription(appointmentId: number): Promise<IAppointment | null> {
    const response = await this.apiService.get<any>(`backoffice/prescription/${appointmentId}/last`)
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }

    const { appointment } = response.data.data
    if (!appointment) return null
    const { counselor = {} } = appointment

    return {
      ...appointment,
      counselor: {
        ...counselor,
        avatar_image: counselor && this.fileService.getFileUrl(counselor.avatar_image),
      },
    }
  }

  public async getUserAppointmentHistoryById(
    userId: string,
    appointmentId?: number,
    query?: Record<string, string>
  ): Promise<IUserAppointmentHistoryView> {
    const response = await this.apiService.get<IUserAppointmentHistoryResponse>(
      `backoffice/appointment/user/${userId}`,
      query,
      true
    )
    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }

    const data = response.data.data.appointments.filter(item => item.id !== appointmentId)

    return {
      total: response.data.data.meta.total,
      appointments: data,
    }
  }

  public async getMedicineList(): Promise<Array<IMedicine>> {
    const response = await this.apiService.get<IMedicineListDTO>(`backoffice/medicine`)

    if (response.status !== ApiResponseStatus.Ok || !response.data.data) {
      throw new Error(response.data.message)
    }

    return response.data.data?.medicine ?? []
  }

  public async adminUpdateFollowupStatus(appointmentId: number, form: any): Promise<IAppointment> {
    const response = await this.apiService.post<any>(
      `backoffice/appointment/${appointmentId}/followup`,
      form
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getCounselorSlot(counselorId: number, date: string): Promise<ITimeSlot[]> {
    // incoming date format is 'd/m/y'
    const [day, month, year] = date.split("/")
    const newDateFormat = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    const response = await this.apiService.get<any>(
      `backoffice/appointment/counselor/${counselorId}?date=${newDateFormat}`
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.slots
  }

  public async revertAppointmentStatus(id: number, reason: string): Promise<any> {
    const response = await this.apiService.post<any>(
      `/api/v1/backoffice/appointment/revert/${id}`,
      { reason }
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async createTimeSlot(data: INewAppointmentTimeSlot): Promise<IAppointment> {
    const response = await this.apiService.post<any>(
      "backoffice/counselor/appointment/createtimeslot",
      data,
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.appointment
  }

  public async updateTimeSlot(
    id: number,
    counselor_id: number,
    counselor_type: String,
    date: String,
    type: String,
    start_time: String,
    end_time: String,
    appointment_time_slot_place_id: number,
    capacity: number,
    routine_start_date: String,
    routine_end_date: String,
    frequency: String,
    is_routine: boolean,
    faculty_id: []
  ): Promise<IAppointment> {
    const response = await this.apiService.put<any>(
      "backoffice/counselor/appointment/updatetimeslot",
      {
        id,
        counselor_id,
        counselor_type,
        date,
        type,
        start_time,
        end_time,
        appointment_time_slot_place_id,
        capacity,
        routine_start_date,
        routine_end_date,
        frequency,
        is_routine,
        faculty_id,
      },
      true
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.appointment
  }

  public async getTimeSlotById(counselor_id: number) {
    const response = await this.apiService.get<any>("backoffice/counselor/appointment/slotid", {
      counselor_id,
    })

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getTimeSlotByDate(counselor_id: number, date: Date) {
    const response = await this.apiService.get<any>("backoffice/counselor/appointment/slotdate", {
      counselor_id,
      date,
    })

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async deleteTimeSlotById(id: number) {
    const response = await this.apiService.delete<any>(
      "backoffice/counselor/appointment/deletetimeslot",
      {
        id,
      }
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async createNewTimeSlot(data: INewAppointmentTimeSlot) {
    const response = await this.apiService.post<any>(
      "backoffice/counselor/appointment/timeslotavailable",
      data
    )
    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.appointment
  }

  public async getLocationList(): Promise<INewAppointmentLocation[]> {
    const response = await this.apiService.get<any>("backoffice/appointment/locations")

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.location
  }

  public async getWeeklyList(): Promise<IWeeklyOption[]> {
    const response = await this.apiService.get<any>("backoffice/counselor/appointment/repeat")

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.repeat
  }

  public async getFacultyList(): Promise<INewAppointmentFaculty[]> {
    const response = await this.apiService.get<any>("backoffice/counselor/appointment/faculty")

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.faculty
  }

  public async updateNewTimeSlot(data: IUpdateNewAppointmentTimeSlot): Promise<void> {
    const response = await this.apiService.put<any>(
      "backoffice/counselor/appointment/timeslotavaliable",
      data
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async deleteNewTimeSlot(slotId: number, date: string, all_series: boolean): Promise<void> {
    const response = await this.apiService.delete<any>(
      "backoffice/counselor/appointment/deleteonetimeslotavailable",
      {
        id: slotId,
        date,
        all_series,
      }
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }
  }

  public async getMyAppointmentByDate(
    date: string,
    mode: "admin" | "counselor" | "psychiatrist",
    status: string,
    caseOwner: string,
    service: string
  ) {
    const response = await this.apiService.get<any>("backoffice/appointment/getList", {
      mode,
      status,
      caseOwner,
      service,
      from: date,
      to: date,
    })

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getMyAppointmentByDateRange(
    from: string,
    to: string,
    mode: "admin" | "counselor" | "psychiatrist",
    status: string,
    caseOwner: string,
    service: string
  ) {
    const response = await this.apiService.get<any>("backoffice/appointment/getList", {
      mode,
      status,
      caseOwner,
      service,
      from,
      to,
    })

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getCalendarByDateRange({
    from,
    to,
    counselor_id,
    counselor_type,
    service_type,
    filter,
  }: {
    from: string
    to: string
    counselor_id?: number
    counselor_type?: string
    service_type?: string
    filter: "all" | "available" | "occupied"
  }): Promise<INewCalendarItem[]> {
    const response = await this.apiService.get<any>(
      "backoffice/counselor/appointment/timeslotCalendarAppointment",
      {
        start_date: from,
        end_date: to,
        counselor_id,
        counselor_type,
        service_type,
        filter,
      }
    )

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data
  }

  public async getHolidays(): Promise<IHoliday[]> {
    const response = await this.apiService.get<any>("backoffice/counselor/appointment/getholiday")

    if (response.status !== ApiResponseStatus.Ok) {
      throw new Error(response.data.message)
    }

    return response.data.data.holidays
  }
}
