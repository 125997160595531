import "reflect-metadata"
import React from "react"
import ReactDOM from "react-dom"
import liff from "@line/liff"
import "mobx-react-lite/batchingForReactDom"
import * as serviceWorker from "./serviceWorker"
import { App } from "./App"
import { BackOfficeHotlineQueue } from "./pages/backoffice/Hotline/Queue/BackofficeHotlineQueue"
import { HotlineContactPage } from "pages/Hotline/HotlineContactPage/HotlineContact"
import "./i18n"

import "./index.less"

ReactDOM.render(
  // <React.StrictMode>
  <App />,

  // </React.StrictMode>, 
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    alert("MindSpace เวอร์ชั่นใหม่ พร้อมใช้งานแล้ว กดตกลงเพื่ออัปเดต")
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" })
    }
    window.location.reload()
  },
})
