import { Matcher, map, redirect, route } from "navi"
import container from "inversify.config"
import type { IAuthService } from "services/authService"
import { TYPES } from "services/types"
import type { IUserService } from "services/userService"
import type { ReactNode } from "react"

export const withGuardLoggedIn = (matcher: Matcher<any>): Matcher<any> => {
  return map((request, context) => {
    const authService = container.get<IAuthService>(TYPES.IAuthService)
    if (authService.isLoggedIn) {
      return redirect("/")
    }
    return matcher
  })
}

export const withAuthentication = (
  matcher: Matcher<any>,
  roles: Array<"customer" | "admin" | "counselor" | "line">
): Matcher<any> => {
  return map(async (request, context) => {
    const authService = container.get<IAuthService>(TYPES.IAuthService)
    const userService = container.get<IUserService>(TYPES.IUserService)
    const customerLogin = authService.isLoggedIn && (await userService.isCustomer)
    const adminLogin = authService.isAdminLoggedIn && (await userService.isAdmin)
    const counselorLogin = authService.isAdminLoggedIn && (await userService.isCounselor)
    const role = roles.find(
      role =>
        (role === "customer" && customerLogin) ||
        (role === "admin" && adminLogin) ||
        (role === "counselor" && counselorLogin)
    )
    if (role) {
      return matcher
    }
    if (roles.includes("customer")) {
      return redirect(`/login?redirect=${encodeURIComponent(request.originalUrl)}`)
    }
    return redirect(`/backoffice/login?redirect=${encodeURIComponent(request.originalUrl)}`)
  })
}

export const withCustomerAuthentication = (matcher: Matcher<any>): Matcher<any> =>
  withAuthentication(matcher, ["customer"])


export const withBackofficeAuthentication = (
  matcher: Matcher<any>,
  roles: Array<"admin" | "counselor"> = ["admin", "counselor"]
): Matcher<any> => withAuthentication(matcher, roles)

export const withAnyAuthentication = (matcher: Matcher<any>): Matcher<any> =>
  withAuthentication(matcher, ["customer", "admin", "counselor"])

export const withoutPermission = (
  matcher: Matcher<any>,
  permissions: Array<"appointment_readonly" | "assessment_readonly">,
  restrictedPage: ReactNode
): Matcher<any> =>
  map(async (request, context) => {
    const userService = container.get<IUserService>(TYPES.IUserService)
    const customer = (await userService.user).customer
    if (customer) {
      for (const permission of permissions) {
        if (
          customer.permissions.find(customerPermission => customerPermission.key === permission)
        ) {
          return route({
            title: "Restricted :Mental Health Platform",
            view: restrictedPage,
          })
        }
      }
    }
    return matcher
  })
