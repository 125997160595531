import React from "react"
import { lazy, map, mount, redirect, route } from "navi"
import {
  withGuardLoggedIn,
  withCustomerAuthentication,
  withBackofficeAuthentication,
  withAnyAuthentication,
} from "./guard"
import { FrontPage } from "pages/FrontPage/FrontPage"
import { Register } from "pages/Register/Register"
import container from "inversify.config"
import { TYPES } from "services/types"
import type { IAuthService } from "services/authService"
import type { IFrontPageService } from "services/frontPageService"
import type { IConfigService } from "services/configService"

const authService = container.get<IAuthService>(TYPES.IAuthService)
const frontPageService = container.get<IFrontPageService>(TYPES.IFrontPageService)
const configService = container.get<IConfigService>(TYPES.IConfigService)

export const routes = mount({
  "/": route(async req => {
    const carouselImages = await frontPageService.getCarouselImageList()

    return {
      title: "Mental Health Platform",
      view: <FrontPage carouselImages={carouselImages} />,
    }
  }),
  "/profile": withAnyAuthentication(lazy(() => import("./profile"))),
  "/appointment": map((req, context) => {
    if (configService.appointmentEnable) {
      return withCustomerAuthentication(lazy(() => import("./appointment")))
    }
    return redirect("/")
  }),
  "/assessment": withAnyAuthentication(lazy(() => import("./assessment"))),
  "/login": withGuardLoggedIn(lazy(() => import("./login"))),
  "/register": map((req, context) => {
    if (!authService.isLoginNormalEnabled) {
      return redirect("/login")
    }
    return route({
      title: "Register: Mental Health Platform",
      view: <Register />,
    })
  }),
  "/workshop": withAnyAuthentication(lazy(() => import("./workshop"))),
  "/backoffice/workshop": withBackofficeAuthentication(lazy(() => import("./backoffice/workshop"))),
  "/article": lazy(() => import("./article")),
  "/backoffice/article": withBackofficeAuthentication(lazy(() => import("./backoffice/article"))),
  "/backoffice/login": lazy(() => import("./backoffice/login")),
  "/backoffice": withBackofficeAuthentication(lazy(() => import("./backoffice"))),
  "/backoffice/appointment": withBackofficeAuthentication(
    lazy(() => import("./backoffice/appointment"))
  ),
  "/backoffice/myappointment": withBackofficeAuthentication(
    lazy(() => import("./backoffice/myappointment")),
    ["counselor"]
  ),
  "/backoffice/accountmanagement": withBackofficeAuthentication(
    lazy(() => import("./backoffice/accountmanagement"))
  ),
  "/backoffice/frontpage": withBackofficeAuthentication(
    lazy(() => import("./backoffice/frontpage"))
  ),
  "/backoffice/assessment": withBackofficeAuthentication(
    lazy(() => import("./backoffice/assessment"))
  ),
  "/backoffice/medicinemanagement": withBackofficeAuthentication(
    lazy(() => import("./backoffice/medicinemanagement"))
  ),
  "/hotline": withAnyAuthentication(lazy(() => import("./hotline"))),
  "/backoffice/hotline": withBackofficeAuthentication(lazy(() => import("./backoffice/hotline"))),
  "/backoffice/blacklist": withBackofficeAuthentication(
    lazy(() => import("./backoffice/blacklist"))
  ),
  "/backoffice/zoom": withBackofficeAuthentication(lazy(() => import("./backoffice/zoom"))),
  "/backoffice/mindspaceplus": withBackofficeAuthentication(lazy(() => import("./backoffice/mindspaceplus"))),
})
